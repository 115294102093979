<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Configurações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Configurações",
    };
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>
  </Layout>
</template>
